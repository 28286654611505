import React from "react";

const Hero = () => {
  return (
    <div className="pt-4 pt-md-06 pt-lg-10 ">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-12 col-md-11">
            <div
              className="text-center mt-10 mb-11 mb-lg-11"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-13 mb-15 landing-title"><span className="Videos">Try Digma</span>for free</h1>
              <p className="font-size-8 mb-15 landing-subtitle">
              Experience the full power of Digma.<br /> Get free 7-day access to all toolkits. Cancel anytime.</p>
              <a href="https://app.digma.io?mode=login" class="m-auto btn text-white btn btn-dodger-blue-2 btn-large rounded-5 font-size-3">Try For Free</a>
            </div>
          </div>
              
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
