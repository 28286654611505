import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing/Hero";
import Company from '../sections/home1/Company';
import Testimonial from '../sections/home1/Testimonial';
import Commit from '../sections/common/Commit';
import WaveReverse from '../sections/common/WaveReverse';
import SEO from '../components/SEO';
import imgC from "../assets/image/free-trial-social.jpg";
import Slider from "react-slick";
import Iimages from '../sections/interactive-images/Content3';
import Ipop from '../sections/popups/Content3';
import Ilinks from '../sections/links/ShortLinks';
import VideoDemo from "../sections/common/VideoDemoNew";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    ><FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"      
      tabIndex={0}
   ><FontAwesomeIcon icon={faAngleRight} />
   </div>
  );
}
const PricingPage = () => {

  const slickSettings = {
    dots: false,
    infinite: true,
    fade:false,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 6000,  
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          dots: true,
          nextArrow: false,
          prevArrow: false, 
          arrows: false,         
        },
      },
    ],         
  };
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: 'site-header--menu-left free-trial',
          headerFluid: false,
          version: 'free-trial-version',
          footerStyle: 'digma',
          pageClassName: 'free-trial-main'
        }}>
        <SEO
          title="Try Digma For Free"
          description="Experience the full power of Digma. Get free 7-day access to all toolkits. Cancel anytime."
          image={imgC}
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/about">About</Link>
              </li>
              </ol>
           </nav>
        </div>
        <Hero />
        <WaveReverse background="transparent" color="#F7F9FC" />
        <Company className="grey pt-15" />
        <WaveReverse background="#F7F9FC" color="#fff" />
        <div className="blob-container">
        <div class="blob">
          <video src="https://cdn.digma.io/videos/pink-blob.mp4" loop muted autoPlay />
        </div>
        <Iimages />        
        </div>
        <WaveReverse background="#fff" color="#F7F9FC" />
        <Ipop className="grey" />
        <div id="pop"></div>
        <div class="digma-popup" data-popupid="1E4BEC11-4B25-4C94-AFE7-C8229EBCC413"/>
        <WaveReverse background="#F7F9FC" color="#fff" />

        <div id="demo" className="container mt-15 text-center l5-content-gallery-img testimonial-one">    
          <h4 className="font-size-10">Fully Engage with our <span className="Videos">Interactive Video</span></h4>
          <Slider
                {...slickSettings}
                  css={`
                    .slick-slide {
                      margin: 0 1rem;
                    }
                    .slick-dots {
                      display: flex !important;
                    }
                  `}
                  className="l6-testimonial"
                >                              
          <div><VideoDemo className="pt-10" title="" name="" projectId="C40BCA72-6583-4C80-BFE3-3E06184FAC60" /></div>
          <div><VideoDemo className="pt-10" title="" projectId="65B89E52-E4A9-4691-95C6-CC13D00596B6" /></div>
          <div><VideoDemo className="pt-10" title="" name="" projectId="E3E2DE77-696B-4DC9-B0C4-633FEB0A6DBE" /></div>
          </Slider>
        </div>        
        <WaveReverse background="#fff" color="#F7F9FC" />
        <Ilinks className="grey" />

        <WaveReverse background="#F7F9FC" color="#fff" />
        <Testimonial className="testimonial-one pt-20 pt-md-20 pt-lg-20 pb-5 pb-md-15 pb-lg-15" />
        <div style={{ overflow: 'hidden', background: '#f7f9fc' }}>
          <Commit />
        </div>
        </PageWrapper>
    </>
  );
};
export default PricingPage;
