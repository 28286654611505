import React from "react";
import imgInteractiveImages from "../../assets/image/pop-up.png";

const Content3 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row pt-0 pb-0 pt-lg-0 pb-lg-0 align-items-center justify-content-center position-static">
          <div className="col-xl-5 col-lg-6 order-lg-1 order-2" data-aos="zoom-in-right" data-aos-once="true" data-aos-delay="750">
            <div className="light-mode-texts mt-0 mt-lg-0 pr-xs-15 pr-sm-14 pr-md-13 pr-lg-0 pl-lg-15">
              <h1
                className="font-size-10 mb-2 letter-spacing-n83"
              >
                <span className="Forms">Popups</span><br /><i>that convert</i>
              </h1>
              <p
                className="font-size-5 mb-5"
              >
                Build your email marketing list, promote offers, collect feedback, upsell and cross-sell and improve your customers journey.</p>
                <p
                className="font-size-5 mb-0"
              >Convert <strong>83%</strong> More Of Your Visitors Into Leads, Subscribers &amp; Buyers.
              </p>
              <div className="mt-9 mt-lg-7">
                <a href="https://app.digma.io?mode=login" className="btn bg-form text-white btn-xl h-55 rounded-5 ">try for free</a>                 
              </div>
            </div>
          </div>
          {/* Right Image */}
          <div className="col-xl-7 col-lg-6 order-lg-2 order-1" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
            {/* content-2 start */}
            <div className="l5-content-gallery-img">
              {/* content img start */}
              <div className="d-xs-flex ml-lg-15 mx-xl-n3">
                <div className="d-flex flex-column w-100 pt-20">
                  {/* single image */}
                  <img className="w-100" src={imgInteractiveImages} alt="" />              
                  {/* single image end */}
                </div>
              </div>
              {/* abs-content end */}
            </div>
            {/* content-2 end */}
          </div>
          {/* End Right Image */}

        </div>
      </div>
    </div>
  );
};

export default Content3;
